import ProTable, { ProTableProps } from '@ant-design/pro-table';
import { ColumnsProductStatus, Product } from '../../../model/product/product.model';
import ProductSelectionForm, { columns as productColumns } from './product-selection-form';
import React, { FC, useEffect, useState } from 'react';
import { fetchProductByIds, fetchProductSkuIds } from './product.reducer';
import { Button, Form, message, Space, Tag, Typography,Image } from 'antd';
import { ModalForm, ProFormTextArea } from '@ant-design/pro-form';

export interface ProductListEditControlProps extends Omit<ProTableProps<Product, { productIds?: string[] }>, 'onChange'> {
  value?: string[];
  onChange?: (products?: string[]) => void;
  requestParams?: any;
  multiple?: boolean;
  fetchFunc?: (params: any) => any;
  hideProductIds?: boolean;
  level?: 'spu' | 'sku',
  procurement?: boolean,//是否采购订单
  numListChange?: any;
  fetchPimList?: any;
}

const ProductListEditControl: FC<ProductListEditControlProps> = props => {
  const { value, onChange,numListChange, fetchPimList,requestParams = {}, multiple = true, procurement = false, level = 'spu', fetchFunc, hideProductIds = false, ...tableProps } = props;
  const [form] = Form.useForm<{ productIds?: string }>();
  const [numList, setNumList] = useState<{ quantity: number, skuId: any }[]>([]);

  useEffect(() => {
    value && numListChange && numListChange?.(numList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  const procurementColumns = [{
    title: '图片',
    hideInSearch: true,
    width:100,
    render: (dom:any, record:any) => record?.product?.mainPicture && <Image width={80} src={record?.product?.mainPicture} />
  }, {
    title: '商品信息',
    dataIndex: ['product', 'status'],
    hideInSearch: true,
    valueEnum: ColumnsProductStatus,
    render: (dom: any, record: any) =>
      <Space direction="vertical">
        <Typography.Text>{record?.product?.name}</Typography.Text>
        <Space>
          skuId：<Typography.Text copyable={true}>{record.id}</Typography.Text>
          <Tag color={record?.product?.status === 'SELLING' ? 'green' : 'red'}>{dom}</Tag>
        </Space>
        <Space>
          SPU编码：<Typography.Text copyable={true}>{record?.product?.code}</Typography.Text>
        </Space>
      </Space>
  }, {
    title: '库存',
    dataIndex: 'quantity'
  }, {
    title: '零售价',
    dataIndex: 'retailPrice'
  }, {
    title: '采购价',
    dataIndex: 'purchasePrice'
  }, {
    title: '采购数量',
    dataIndex: 'num',
    render: (dom: any, record: any) => numList.concat().filter(e => e.skuId === record.id)?.[0]?.quantity || 1
  }];

  const changeNumList = (res: { quantity: number, skuId: any }[]) => {
    if (numList.length === 0) {
      setNumList(res);
    } else {
      
      const arr = numList.concat().map(e => e.skuId);
      const newArr1 = res.concat().filter(e => arr.indexOf(e.skuId) > -1) || [];//已存在的
      const newArr2 = res.concat().filter(e => arr.indexOf(e.skuId) === -1) || [];//新增的
      const all = numList.concat(newArr2).map(e => {
        const filterArr = newArr1.filter(s => s.skuId === e.skuId);
        return {
          ...e,
          quantity: filterArr.length > 0 ? filterArr[0].quantity : e.quantity
        }
      })
      setNumList(all);
    }

  }

  return (
    <ProTable<Product, { productIds?: string[] }>
      rowKey="id"
      columns={!procurement ? productColumns : procurementColumns}
      options={false}
      search={false}
      params={{ productIds: value || [] }}
      rowSelection={{}}
      // headerTitle="限下列商品参与活动"
      tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) =>
        <Button type="link" size="small"
          onClick={() => {
            const newValue = value?.concat().map(e => e + '');
            setNumList(numList.concat().filter(e => selectedRowKeys.indexOf(e.skuId) === -1 ));
            onChange?.((newValue || []).filter(item => selectedRowKeys?.indexOf(item + '' || '') < 0));
            onCleanSelected();
          }}>
          删除
        </Button>}
      toolBarRender={() => [
        <ProductSelectionForm
          trigger="添加"
          multiple={multiple}
          requestParams={requestParams}
          fetchFunc={fetchFunc}
          level={level}
          fetchNumList={changeNumList}
          onFinish={(productIds) => {
            return new Promise<any>((resolve, reject) => {
              const newValue = value?.concat().filter(e => typeof (e) !== 'object');
              const newIds = (newValue || []).concat(productIds.filter(id => !(newValue || []).includes(id)));
              if (newIds.length > 200) {
                message.error('商品数量最多为200，请重新选择！');
                resolve(false);
              } else {
                onChange?.(newIds);
                resolve(true);
              };
            })

          }}
        />,
        !hideProductIds && <ModalForm<{ productIds?: string }>
          form={form}
          title="批量输入商品ids"
          width={800}
          trigger={<Button>快速添加</Button>}
          modalProps={{
            destroyOnClose: true,
          }}
          preserve={false}
          onVisibleChange={visible => visible && form.setFieldsValue({ productIds: (value || []).toString() })}
          onFinish={async (values) => {
            const newIds = (values.productIds || '').trim().split(',').map(item => item.trim()).filter(item => !!item);
            if (newIds.length > 200) {
              message.error('商品数量最多为200，请重新输入！');
              return false;
            } else {
              onChange?.(newIds);
              return true;
            };
          }}
        >
          <Typography.Text>批量粘贴商品Id到下面，以"，"分割，然后点击确定</Typography.Text>
          <Typography.Text italic>例如：1430438289763639297,1455712100170674178</Typography.Text>
          <ProFormTextArea
            name="productIds"
            allowClear={true}
            placeholder="1430438289763639297,1455712100170674178"
            rules={[{ pattern: RegExp('^\\d+(,(\\s)*\\d+)*$'), message: '数据格式不正确' }]}
          />
        </ModalForm>]}
      request={async (
        params: {
          productIds?: string[];
          pageSize?: number;
          current?: number;
        },
      ) => {
        const { productIds = [], pageSize = 20, current = 1 } = params;
        // const allData
        const ids = productIds.slice((current - 1) * pageSize, current * pageSize);
        const products = !procurement ? await fetchProductByIds(ids) : (requestParams.customerId ? await fetchProductSkuIds({ ids, customerId: requestParams.customerId }) : []);
        fetchPimList && fetchPimList(products)
        console.log(products,fetchPimList)
        return {
          data: products as any,
          // success 请返回 true，
          // 不然 table 会停止解析数据，即使有数据
          success: true,
          // 不传会使用 data 的长度，如果是分页一定要传
          total: productIds.length,
        };
      }}
      {...tableProps}
    />);
}

export default ProductListEditControl;