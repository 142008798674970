import { PlusOutlined } from '@ant-design/icons';
import { ActionType, EditableProTable, ProColumns } from '@ant-design/pro-table';
import { Button, Col, Form, Row, message } from "antd";
import { Item, SpecDefineTuple } from 'model/product/product.model';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import EditableInput from './sku-editable-input';
import SkuSpecGeneratorForm, { SpecValueRow } from './sku-spec-generator-form';
import SkuSpecEditControl from './sku_spec_edit_control';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import Title from 'antd/lib/typography/Title';

export interface SkuEditListProps {
  onChange?: (value: Item[]) => Item[];
  value?: Item[];
  specListData: SpecDefineTuple[];//specList 总数据
  // specListDataChange: (res: any) => void;
}

// let timeout: any = null;

const SkuEditList: FC<SkuEditListProps> = (props) => {
  const { value, specListData, onChange } = props;
  const [disabledTag, setDisabledTag] = useState(false);
  const [stagingValue,setStagingValue] = useState([]);

  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>(() =>
    value?.map(item => item.id + '') || [],
  );

  // useEffect(() => {
  //   let arr: any = [];
  //   specListData.forEach(e => {
  //     e.specValueList?.forEach(s => {
  //       s.status === 0 && (arr.push(s.id));
  //     })
  //   });
  //   const newValue: any = value?.concat().map(e => {
  //     let status = e.status;
  //     e.specs?.forEach(item => {
  //       arr.indexOf(item.v_id) > -1 && (status = 0)
  //     });
  //     return {
  //       ...e,
  //       status,
  //       weight: Math.random().toString(5),
  //     }
  //   });
  //   setStagingValue(newValue);
  //   onChange?.(newValue);
  // }, [specListData]);


  // const handleChange = (value: any) => {
  //   if (timeout) {
  //     clearTimeout(timeout);
  //     timeout = null;
  //   };
  //   const fake = async () => {
  //     value && setStagingValue(value);
  //     value && setEditableRowKeys(value?.map((item: any) => item.id + '') || []);
  //     value && onChange?.(value);
  //   }
  //   timeout = setTimeout(fake, 500);
  // };

  const columns: ProColumns<Item>[] = [{
    title: '销售价格',
    dataIndex: 'retailPrice',
    width: 40,
    renderFormItem: (dom, record) => {
      return <EditableInput valueType="money" data={record?.record?.retailPrice} />
    }
  }, {
    title: <> <span className={'bitian'}></span>规格编码<span style={{ color: '#999', marginLeft: '4px' }}>(必填项)</span></>,
    dataIndex: 'code',
    width: 60,
    render: (dom, record) => {
      return <EditableInput valueType="text" data={record?.code} />
    }
  }, {
    title: '重量(单位kg)',
    dataIndex: 'weight',
    width: 50,
    renderFormItem: (dom, record) => {
      return <EditableInput valueType="digit" digitSuffix={3} data={record?.record?.weight} />
    }
  }, {
    title: '成本价',
    dataIndex: 'costPrice',
    width: 50,
    renderFormItem: (dom, record) => {
      return <EditableInput digitSuffix={2} valueType="money" data={record?.record?.costPrice} />
    }
  }, {
    title: '市场价',
    dataIndex: 'originPrice',
    width: 50,
    renderFormItem: (dom, record) => {
      return <EditableInput digitSuffix={2} valueType="money" data={record?.record?.originPrice} />
    }
  }, {
    title: '起订倍数',
    dataIndex: 'minOrderQuantity',
    width: 50,
    renderFormItem: (dom, record) => {
      return <EditableInput valueType="digit" digitSuffix={0} data={record?.record?.minOrderQuantity} />
    }
  }, {
    title: '状态',
    dataIndex: 'status',
    valueType: 'switch',
    width: 50,
    fieldProps: {
      checkedChildren: '启用',
      unCheckedChildren: '禁用'
    }
  },
    // {
    //   title: '操作',
    //   dataIndex: 'originPrice',
    //   valueType: 'money',
    //   width: 40,form1=> {
    //     return <span onClick={() => {
    //       onChange?.(value || []);
    //       console.log(value)
    //       // let newArr = [...value || []]
    //       // newArr?.splice(record.index, 1)    
    //       // props.specListDataChange(newArr);
    //     }}>删除{record.index}</span>
    //   },
    // }
  ];



  useEffect(() => {
    // handleChange(value);
    if(value && value.length > 0){
      value && setStagingValue(value as any);
      value && setEditableRowKeys(value?.map((item: any) => item.id + '') || []);
      value && onChange?.(value);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [form] = Form.useForm();
  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 4 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 20 },
  //   },
  // };
  const buildColumns = useCallback((): ProColumns<Item>[] => {
    const specColumn: ProColumns<Item> = {
      title: '规格值',
      dataIndex: 'specs',
      width: 60,
      renderFormItem: (record: any) => {
        return <SkuSpecEditControl data={record?.entity?.specs} specListData={specListData}></SkuSpecEditControl>
      }
    };
    return [specColumn].concat(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specListData]);
  const actionRef = useRef<ActionType>();
  const addSkuRow = (item: SpecValueRow, lastTag?: boolean) => {
    const id = Math.random().toString(36).substring(7);
    const newSku = {
      id,
      retailPrice: 0,
      sellStockNum: 0,
      code: 0,
      costPrice: 0,
      originPrice: 0,
      minOrderQuantity: 1,
      soldNum: 0,
      maxPerPerson: 0,
      maxPerOrder: 0,
      pointPrice: 0,
      maxPerPersonMonth: 0,
      weight: 0,
      specs: item.specs,
      status: 1,
    };
    actionRef.current?.addEditRecord?.(newSku, {
      position: 'bottom',
      recordKey: id,
      newRecordType: 'dataSource'
    });
    lastTag && setDisabledTag(false);
    lastTag && message.success('规格创建完成');
  };

  return <>
    <Title style={{ marginTop: '24px' }} level={5}>SKU列表</Title>
    <Row>
      <Col style={{ marginLeft: '24px', marginRight: '44px' }} span={2}>
        <SkuSpecGeneratorForm
          specListData={specListData}
          skuList={value}
          trigger={<Button disabled={disabledTag} style={{ marginBottom: '16px' }} type="dashed" size="middle"
            icon={<PlusOutlined />}>新增Sku</Button>}
          title="选择规格"
          onFinish={async (items) => {
            // message.info('创建规格中,请勿重复操作！');
            setDisabledTag(true)
            items?.forEach((item, index) => {
              if (index === items.length - 1) {
                addSkuRow(item, true);
              } else {
                addSkuRow(item, false);
              }
            });
            return true;
          }}
          disabledTag={disabledTag}
          modalProps={{
            destroyOnClose: true,
          }}
        >
        </SkuSpecGeneratorForm>
      </Col>
      <Col span={2}>
        <ModalForm
          title="批量填入"
          trigger={<Button type='primary' style={{ marginBottom: '5px' }}>
            批量填入
          </Button>}
          onFinish={async (data: any) => {
            return new Promise<boolean>((resolve) => {
              let newData = [...value || []]
              newData.concat().map((item: any) => {
                item.retailPrice = data.retailPrice ? data.retailPrice : item.retailPrice
                item.code = data.code ? data.code : item.code
                item.weight = data.weight ? data.weight : item.weight
                item.costPrice = data.costPrice ? data.costPrice : item.costPrice
                item.originPrice = data.originPrice ? data.originPrice : item.originPrice
                item.minOrderQuantity = data.minOrderQuantity ? data.minOrderQuantity : item.minOrderQuantity
                return item;
              });
              onChange?.(newData);
              resolve(true);
            })
          }}
        >
          <ProFormText
            name="retailPrice"
            label="销售价格"
            width="md"
          />
          <ProFormText
            name="code"
            label="规格编码"
            width="md"
          />
          <ProFormText
            name="weight"
            label="重量"
            width="md"
          />
          <ProFormText
            name="costPrice"
            label="成本价"
            width="md"
          />
          <ProFormText
            name="originPrice"
            label="市场价"
            width="md"
          />
          <ProFormText
            name="minOrderQuantity"
            label="起订倍数"
            width="md"
          />
        </ModalForm>
      </Col>
      <Col span={24}>
        <EditableProTable<Item>
          actionRef={actionRef}
          rowKey="id"
          recordCreatorProps={{
            position: 'bottom',
            // 每次新增的时候需要Key
            record: () => ({
              id: Math.random().toString(36).substring(7),
            }),
            hidden: true
          }}
          search={false}
          pagination={false}
          options={false}
          columns={buildColumns()}
          value={stagingValue}
          onChange={(newValue) => {
            onChange?.(newValue);
          }}
          editable={{
            type: 'multiple',
            form: form,
            editableKeys,
            actionRender: (row, config, defaultDoms) => (row.id && []) || [defaultDoms.delete],
            onValuesChange: (record, recordList) => {
              const newRecordList = recordList.concat().map(e => {
                (e.status && (e.status = 1)) ? (e.status = 1) : (e.status = 0);
                return e;
              });
              // console.log('newRecordList0000000000000000000000000000', newRecordList)
              onChange?.(newRecordList);
            },
            onChange: setEditableRowKeys,
          }}
        />
      </Col>
    </Row>
  </>
}

export default SkuEditList;