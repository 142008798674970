import axios from "axios";
import { defaultFund, Fund } from "model/financial/fund.model";
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'fund';
const apiUrl = '/api/yumei-mall-store/seller/bank-roll';
const standardReducerImpl = new StandardReducerImpl<Fund>(entity, defaultFund, apiUrl);
export type FundState = StandardState<Fund>;
export const fund = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 资金调整
export const fundAdjust: any = async(data:any)  => {
    const result = await axios.post(`/api/yumei-mall-store/seller/bank-roll/manual/adjustment`, data);
    return result
};

// 资金明细
export const fetchFundDetailsList = async (data:any): Promise<any> => {
    const result = await axios.get(`/api/yumei-mall-store/seller/bank-roll/transaction`, {params: {...data}});
    return result
}


// 设置大商钱包
export const setBigCustomer = async (data:any): Promise<any> => {
    const result = await axios.get(`/api/yumei-mall-store/seller/big-customer/${data.id}`);
    return result
}


// 添加账户
export const addNewAccount = async (data?: any): Promise<any[]> => {
    return await axios.post('/api/yumei-mall-store/seller/bank-roll/create', data);
}

