import { FC, ReactNode } from 'react';
import { Storage } from 'utils/storage-util';

export interface AuthorizedProps {
  authority: string;
  children?: ReactNode;
}

const Authorized: FC<AuthorizedProps> = props => {
  const menus = Storage.session.get('menus');
  const pathname = window.location.pathname.split('/').filter(e => e !== '');
  const { authority, children } = props;
  console.log(menus,props,pathname,'======')
  
  const operateArr = menus.filter((e:any) => e.menuCode === pathname[0])?.[0]?.children.filter((e: any) => e.menuCode === pathname[1])?.[0]?.children?.concat().map((e:any) => e.menuCode)  || [];

  return (
    <>
      {operateArr.indexOf(authority) > -1 && children}
    </>
  );
};

export default Authorized;
