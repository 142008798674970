import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDatePicker, ProFormDigit, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./customer.reducer";
import { Customer } from "model/customerCore/customer.model";
import { Col, Form, Row } from "antd";
import { useEnumValue } from "common/enum/use-enum-value";
import { searchSafeCode } from "pages/dealer/customer/detailtables/decryptText";

export interface CustomerEditProps extends EditStateAndDispatchProps<Customer>, StateProps, DispatchProps, RouteComponentProps {
}

const CustomerEdit: FC<CustomerEditProps> = (props) => {

  const [form] = Form.useForm();
  const [entityCopy, setEntityCopy] = useState<any>({});
  const { entity } = props;
  const { convertEnumToOptionType } = useEnumValue();

  useEffect(() => {
    if (!!entity.id) {
      const entityCopy = JSON.parse(JSON.stringify(entity));
      searchSafeCode(entity.id || '', entity.phone || '').then(e => {
        entityCopy.phone = e;
        searchSafeCode(entity.id || '', entity.credentialsNo || '').then(e => {
          entityCopy.credentialsNo = e;
          setEntityCopy(entityCopy);
        });
      });
    }
  }, [entity]);

  return (
    <EditPage<Customer>
      title="客户"
      form={form}
      {...props}
      entity={entityCopy}
    >
      {!!entity.id && <Row justify="start">
        <Col span='7'>
          <ProFormText
            name="name"
            label="姓名"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormDigit
            name="phone"
            label="手机号"
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormDatePicker
            name="birthday"
            label="生日"
            width="md"
          />
        </Col>

        <Col span='7'>
          <ProFormSelect
            options={convertEnumToOptionType('credentialsType')}
            name={["credentialsType"]}
            label="身份证类型"
          />
        </Col><Col span='1'></Col>
        <Col span='7'>
          <ProFormText
            name="credentialsNo"
            label="身份证号"
          />
        </Col>
      </Row>}
      {!entity.id && <Row justify="start">
        <Col span='7'>
          <ProFormText
            name="nickName"
            label="姓名"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormText
            name="phoneRegion"
            label="区号"
            initialValue={'+86'}
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormDigit
            name="phoneNumber"
            label="手机号"
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormText
            name="invitorCode"
            label="上级授权号"
          />
        </Col>
        <Col span='1'></Col>
      </Row>}
    </EditPage>);
};

const mapStateToProps = ({ customer }: RootState) => ({
  entity: customer.entity,
  updateSuccess: customer.updateSuccess,
  updating: customer.updating,
  createSuccess: customer.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
