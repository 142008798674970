import { FC, memo, useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { defaultLanguageMap } from 'model/decorate/examdecorate.model';
import '../style/config.less';
import { examPageTypeList } from 'pages/examMng/api';
import { fetchLevelList } from 'pages/dealer/unit/unit.reducer';
import { internationalList } from 'components/IntelFormText';

interface SetProps {
  dataField?: any
  id?: string
  methods?: any
  selectedInfo?: any
  pageData: any
  pageConfig: any
  language?: string
  platform: string
  otherInfo?: any
}

export const ConfigSet: FC<SetProps> = memo(({ dataField, id, methods, pageData, pageConfig, language, platform }) => {
  const [dataFieldPrivate, setDataFieldPrivate] = useState<any>()
  const [updateTitleTimer, setUpdateTitleTimer] = useState<any>(0);
  const [pageTypeList, setPageTypeList] = useState<any>([]);
  const [levelList, setLevelList] = useState<any>([]);

  useEffect(() => {
    setDataFieldPrivate('')
    dataField.examLevel = dataField.examLevel instanceof Array  ? dataField.examLevel || [] :  dataField.examLevel?.split(',').map(Number) || []
    setTimeout(() => {
      setDataFieldPrivate(dataField)
    }, 300)
  }, [dataField])
  useEffect(() => {
    getpageType()
    getLevelList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFieldPrivate])


  // 获取等级列表
  const getLevelList = async () => {
    const list: any = await fetchLevelList();
    let LevelList = list.map((item: any) => ({
      value: item.id,
      label: internationalList(item.levelName)
    }))
    setLevelList(LevelList)
    let levelAll = list.map((item: any) => item.id )
    if(levelAll) {
      pageData.examItemDto = {
        ...pageData.examItemDto,
        examLevel: levelAll
      }
      methods.updateMoreState({
        pageData: {
          ...pageData
        }
      })
    }
  
  }

  // 获取页面类型
  const getpageType = async () => {
    const data: any = await examPageTypeList();
    let list = data.data.map((item: any) => ({
      value: item?.id,
      label: item?.name,
    }))
    setPageTypeList(list)
  }


  const updateTitle = (value: string) => {
    if (updateTitleTimer) {
      setDataFieldPrivate({
        ...dataFieldPrivate,
        title: value
      })
      clearTimeout(updateTitleTimer)
      setUpdateTitleTimer(0)
    }
    let timer = setTimeout(() => {
      methods.updateModuleData({
        ...dataFieldPrivate,
        title: value
      }, id)
      setUpdateTitleTimer(0)
    }, 500)
    setUpdateTitleTimer(timer)
  }
  return (

    <div className="config-set config">
      {
        dataFieldPrivate &&
        <>
          <Form.Item label="页面名称">
            <Input defaultValue={dataFieldPrivate.title} onBlur={
              (e) => {
                updateTitle(e.target.value)

              }
            } />
          </Form.Item>
          {
            defaultLanguageMap[platform][0].language === language &&
            <>

              <Form.Item label="页面类型">

                <Select defaultValue={pageData.type }  placeholder='请选择页面类型' onChange={
                  (e) => {
                    console.log(e + '哈哈')
                    pageData.type = e
                    methods.updateMoreState({
                      pageData: {
                        ...pageData
                      }
                    })
                  }
                }>
                  {
                    pageTypeList?.map((v: any, i: number) => {
                      return <Select.Option value={v.value} key={i}>{v.label}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>

              <Form.Item label="考试时间(分钟)">
                <Input defaultValue={pageData?.examItemDto?.examTime} type='number' placeholder='请输入考试时间(分钟)' onChange={
                  (e) => {
                    pageData.examItemDto = {
                      ...pageData.examItemDto,
                      examTime: e.target.value
                    }
                    methods.updateMoreState({
                      pageData: {
                        ...pageData
                      }
                    })
                  }
                } />
              </Form.Item>
              <Form.Item label="是否重试">
                <Select placeholder='请选择是否重试' defaultValue={pageData?.examItemDto?.isRetry} onChange={
                  (e) => {
                    pageData.examItemDto = {
                      ...pageData.examItemDto,
                      isRetry: e
                    }
                    methods.updateMoreState({
                      pageData: {
                        ...pageData
                      }
                    })
                  }
                }>
                  <Select.Option value={1} key={0}>是</Select.Option>
                  <Select.Option value={0} key={1}>否</Select.Option>
                </Select>
              </Form.Item>



              <Form.Item label="重试次数">
                <Input defaultValue={pageData?.examItemDto?.retryNum} type='number' placeholder='请输入重试次数' onChange={
                  (e) => {
                    pageData.examItemDto = {
                      ...pageData.examItemDto,
                      retryNum: e.target.value
                    }
                    methods.updateMoreState({
                      pageData: {
                        ...pageData
                      }
                    })
                  }
                } />
              </Form.Item>

              <Form.Item label="可使用等级">
                <Select  mode="multiple" value={pageData?.examItemDto?.examLevel} placeholder="可使用等级" onChange={
                  (e) => {
                    pageData.examItemDto = {
                      ...pageData.examItemDto,
                      examLevel: e.join(',')
                    }
                    methods.updateMoreState({
                      pageData: {
                        ...pageData
                      }
                    })
                  }
                }>
                  {
                    levelList?.map((v: any, i: number) => {
                      return <Select.Option value={v.value} key={i}>{v.label}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>

              <Form.Item label="排序类型">
                <Select placeholder='请选择排序类型 ' defaultValue={dataField.sortType} onChange={
                  (e) => {
                    pageData.examItemDto = {
                      ...pageData.examItemDto,
                      sortType: e
                    }
                    methods.updateMoreState({
                      pageData: {
                        ...pageData
                      }
                    })
                  }
                }>
                  <Select.Option value={1} key={1}>随机排序</Select.Option>
                  <Select.Option value={2} key={2}>正序</Select.Option>
                  <Select.Option value={3} key={3}>倒叙</Select.Option>
                </Select>
              </Form.Item>


              {/* <Form.Item label="页面背景">
                <div className="color-set-cont">
                  <Popover content={
                    <SketchPicker
                      color={dataField.bgColor}
                      onChange={
                        (e: any) => {
                          methods.updateModuleData({
                            ...dataField,
                            bgColor: e.hex
                          }, id)
                        }
                      }
                    />
                  } title="" trigger="click">
                    <p className="color-hover" style={
                      {
                        backgroundColor: dataField.bgColor
                      }
                    }></p>
                  </Popover>
                  <Button onClick={
                    () => {
                      dataField.bgColor = '#f9f9f9'
                      methods.updateModuleData({
                        ...dataField,
                        bgColor: '#f9f9f9'
                      }, id)
                    }
                  }>默认</Button>
                </div>
              </Form.Item> */}

              {/* <Form.Item label="页面风格" >
                <Switch checkedChildren="Light" unCheckedChildren="Dark" onChange={
                  (e) => {
                    methods.updateModuleData({
                      ...dataField,
                      style: e ? 'light' : 'dark'
                    }, id)
                  }
                } checked={dataField.style === 'light'} />

              </Form.Item> */}
            </>
          }
        </>
      }
    </div>
  )
})
