import { FAILURE, REQUEST, SUCCESS } from 'reducers/action-type.util';
import { BaseModel } from 'model/base.model';
import {
  CrudCopyGetAction,
  CrudDeleteAction,
  CrudGetAction,
  CrudGetAllAction,
  CrudPostAction,
  CrudPutAction,
  QueryParameter
} from 'reducers/redux-action.type';
import axios from 'axios';
import { cleanEntity } from 'utils/entity-utils';
import { Reducer } from 'redux';
import { isArray } from 'lodash';

export interface ApiUrl {
  listUrl?: string;
  detailUrl?: string;
  createUrl?: string;
  editUrl?: string;
  deleteUrl?: string;
  copyUrl?: string;
}


export const
  STANDARD_ACTION_TYPES = {
    FETCH_LIST: 'FETCH_LIST',
    FETCH_DOWNLOAD_LIST: 'FETCH_DOWNLOAD_LIST',
    FETCH: 'FETCH',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    UPDATE_LEVEL_CHANGE: 'UPDATE_LEVEL_CHANGE',
    DELETE: 'DELETE',
    RESET: 'RESET',
    AUDIt: 'AUDIt',
    COPY: 'COPY',
    CLEAR_ENTITIES: 'CLEAR_ENTITIES'
  };

export type StandardState<T extends BaseModel> = Readonly<{
  loading: boolean;
  errorMessage: string | null;
  fieldListErrors: any;
  entities: T[];
  entity: T;
  total: number;
  updating: boolean;
  updateSuccess: boolean;
  createSuccess: boolean;
}>;

export const initialState = <T extends BaseModel>(): StandardState<T> => {
  return {
    loading: false,
    errorMessage: null,
    fieldListErrors: null,
    entities: [] as T[], // as ReadonlyArray<any>,
    entity: {} as T,
    total: 0,
    updating: false,
    updateSuccess: false,
    createSuccess: false
  };
};

const deepCopyEntityData = (entity: any, ignoreProperties: String[]): any => {
  const data = ['number', 'string'].indexOf(typeof (entity)) > -1 ? entity : { ...entity, id: undefined };

  const propertyNameList = Object.keys(entity);
  propertyNameList.forEach(propertyName => {
    if ((data[propertyName] instanceof Object) && ignoreProperties.indexOf(propertyName) < 0) {
      let _return;
      if ((data[propertyName] instanceof Array)) {
        _return = [];
        for (let i = 0; i < data[propertyName].length; i++) {
          typeof (data[propertyName][i]) !== 'string' ?
            _return.push(deepCopyEntityData(data[propertyName][i], ignoreProperties)) :
            _return.push(data[propertyName][i]);
        };
        data[propertyName] = _return;
      } else {
        data[propertyName] = deepCopyEntityData(data[propertyName], ignoreProperties);
      }
    }
  });
  return data;
};

export class StandardReducerImpl<T extends BaseModel> {
  private readonly entity: string;
  private readonly defaultItem: T;
  private readonly apiUrl: ApiUrl | string;

  constructor(entity: string, defaultItem: T, apiUrl: ApiUrl | string) {
    this.entity = entity;
    this.defaultItem = defaultItem;
    this.apiUrl = apiUrl;
  }

  actionType(actionType: String): string {
    return `${this.entity}/${actionType}`;
  }

  buildStandardReducer(customizeReducer?: Reducer<StandardState<T>, any>): Reducer<StandardState<T>, any> {
    return (state: StandardState<T> = initialState(), action: any): StandardState<T> => {
      // 如果有自定义的reducer.
      // customizeReducer && customizeReducer(state, action);

      switch (action.type) {
        case REQUEST(STANDARD_ACTION_TYPES.CLEAR_ENTITIES):
        case SUCCESS(STANDARD_ACTION_TYPES.CLEAR_ENTITIES):
        case FAILURE(STANDARD_ACTION_TYPES.CLEAR_ENTITIES):
          return {
            ...state,
            loading: false,
            entities: [],
            total: 0,
          }
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.FETCH_DOWNLOAD_LIST)):
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.FETCH)):
          return {
            ...state,
            errorMessage: null,
            updateSuccess: false,
            loading: true,
          };
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.CREATE)):
          return {
            ...state,
            errorMessage: null,
            createSuccess: false,
            updating: true,
          };
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.UPDATE)):
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.UPDATE_LEVEL_CHANGE)):
        case REQUEST(this.actionType(STANDARD_ACTION_TYPES.DELETE)):
          return {
            ...state,
            errorMessage: null,
            updateSuccess: false,
            updating: true,
          };
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.FETCH_DOWNLOAD_LIST)):
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.FETCH)):
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.CREATE)):
          return {
            ...state,
            loading: false,
            updating: false,
            createSuccess: false,
            errorMessage: action.payload,
            fieldListErrors: action.payload?.response?.data || [],
          }
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.UPDATE)):
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.UPDATE_LEVEL_CHANGE)):
        case FAILURE(this.actionType(STANDARD_ACTION_TYPES.DELETE)):
          return {
            ...state,
            loading: false,
            updating: false,
            updateSuccess: false,
            errorMessage: action.payload,
            fieldListErrors: action.payload?.response?.data || [],
          };
        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
          //控制台正常返回
          const data = {
            ...state,
            loading: false,
            entities: action.payload.data,
            createSuccess: false,
            total: parseInt(action.payload.headers['x-total-count'], 10),
          };
          //大后台正常返回
          const middleGroundData = {
            ...state,
            loading: false,
            entities: action.payload.data?.res?.datas || [],
            total: action.payload.data?.res?.count || 0,
          };
          //中台台异常返回
          const middleGroundErrorData = {
            ...state,
            loading: false,
            updating: false,
            createSuccess: false,
            errorMessage: action.payload?.data?.msg || '',
            fieldListErrors: action.payload?.data?.msg || '',
          };
          return Array.isArray(action.payload.data) ? data : (action.payload.data?.code === '10000' ? middleGroundData : middleGroundErrorData);

        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.FETCH_DOWNLOAD_LIST)):
          return {
            ...state,
            loading: false,
            total: parseInt(action.payload.headers['x-total-count'], 10),
          };
        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.FETCH)):
          const FETCHdata = {
            ...state,
            loading: false,
            entity: (['10000', '20000'].indexOf(action.payload.data?.code) > -1 && action.payload.data?.res?.datas) ? action.payload.data?.res?.datas?.[0] || {} : action.payload.data,
          };
          return FETCHdata
        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.CREATE)): {
          return {
            ...state,
            updating: false,
            entity: action.payload.data,
            createSuccess: true,
            updateSuccess: true,
          };
        }
        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.UPDATE)): {
          if(window.location.pathname === '/pim/product'){
            window.location.reload();
          }
          // 商品：更多-操作-返回数组对象 
          const entity = isArray(action.payload.data) ? action.payload.data[0] : action.payload.data;
          const putEntity: any = state.entities.filter((e: any) => e.id ? e?.id === entity?.id : e?.code === entity?.code)[0];
          const keys = entity ? Object.keys(entity) : [];
          (putEntity && keys.length > 0) && keys.forEach(e => {
            putEntity[e] = entity[e];
          });
          // 刷新修改的数据行。
          let entities = (state.entities && state.entities.concat()) || [];
          isArray(action.payload.data) && action.payload.data.concat().forEach((actionData: any) => {
            entities = entities.map((item: any) => {
              return ((item.id ? item?.id === actionData?.id : item.code === actionData.code) && ({
                ...item,
                ...actionData
              })) || item
            });
          });
          return {
            ...state,
            updating: false,
            updateSuccess: true,
            entity: putEntity ? putEntity : entity,
            entities: entities,
          };
        }
        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.UPDATE_LEVEL_CHANGE)): {
          // 二级编辑
          const entity = action.payload.data;
          let newEntities: any = state.entities.concat(); 
          if (entity.level === 1) {
            newEntities = newEntities.map((e: any) => {
              return {
                ...e,
                level: e.id === entity.id ? entity.level : e.level,
                name: e.id === entity.id ? entity.name : e.name,
                sort: e.id === entity.id ? entity.sort : e.sort,
              }
            })
          } else if (entity.level === 2) {
            newEntities = newEntities.map((e: any) => {
              return {
                ...e,
                children: e.children.concat().map((childrenItem: any) => {
                 
                  return {
                    ...childrenItem,
                    level: childrenItem.id === entity.id ? entity.level : childrenItem.level,
                    name: childrenItem.id === entity.id ? entity.name : childrenItem.name,
                    sort: childrenItem.id === entity.id ? entity.sort : childrenItem.sort,
                  }
                })
              }
            })
          }
          return {
            ...state,
            updating: false,
            updateSuccess: true,
            entity: entity,
            entities: newEntities,
          };
        }

        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.DELETE)): {
          let entities: any = [], total: number = 0;
          const id = action?.payload?.config?.params?.id;
          if (action?.payload?.config?.data) {
            const ids = JSON.parse(action?.payload?.config?.data).map((e: number | string) => e + '');
            entities = state.entities.filter((value: any) => ids.indexOf(value.id + '') === -1);
            total = state.total - (state.entities.length - entities.length);
          } else {
            
            entities = state.entities.filter((value: any) => {
              return value.id ? (value.id + '' !== id + '') : (value.code + '' !== id + '')
            });
            total = state.total - (state.entities.length - entities.length);
          };

          //多级删除
          !!(state.entities?.[0] as any)?.level && (entities = state.entities.concat().filter((value: any) => {
            return value.id ? (value.id + '' !== id + '') : (value.code + '' !== id + '')
          }).concat().map((value: any) => {
            return !!value?.children ? {
              ...value,
              children: value?.children.concat().filter((item: any) => (item.id + '') !== id + ''),
            } : value;
          }));

          return {
            ...state,
            entities: entities,
            total: total,
            updating: false,
            updateSuccess: true,
            entity: {} as T,
          };
        }
        case SUCCESS(this.actionType(STANDARD_ACTION_TYPES.COPY)):
          const ignoreArr = action.payload.config.params?.ignoreArr || []; //此字段决定忽略哪些字段下的id删除
          // ['userGroup', 'gifts', 'userGroup']
          const entity = deepCopyEntityData(action.payload.data, ignoreArr);
          return {
            ...state,
            loading: false,
            entity,
          };
        case this.actionType(STANDARD_ACTION_TYPES.RESET):
          return {
            ...state,
            entity: this.defaultItem,
            // ...action.payload || this.defaultItem || {},
          };
        default:
          return (customizeReducer && customizeReducer(state, action)) || state;
      }
    }
  }

  getEntities: CrudGetAllAction<T> = (params?: QueryParameter, download?: boolean) => dispatch => {

    return dispatch({
      type: this.actionType(download ? STANDARD_ACTION_TYPES.FETCH_DOWNLOAD_LIST : STANDARD_ACTION_TYPES.FETCH_LIST),
      payload: axios.get<any>((this.apiUrl as any)?.listUrl || this.apiUrl, {
        params,
      })
    });
  };

  clearEntities = () => (dispatch: any) => {
    return dispatch({
      type: STANDARD_ACTION_TYPES.CLEAR_ENTITIES,
      payload: new Promise<void>((resolve, reject) => resolve())
    });
  };

  getEntity: CrudGetAction<T> = (id, togetherParams) => { 
    const requestUrl = (this.apiUrl as any)?.detailUrl ? `${(this.apiUrl as any).detailUrl}/${id}` : `${this.apiUrl}/${id}`;
    return {
      type: this.actionType(STANDARD_ACTION_TYPES.FETCH),
      payload: axios.get<any>(requestUrl),
    };
  };


  copyEntity: CrudCopyGetAction<T> = (id,ignoreArr) => {
    const requestUrl = `${(this.apiUrl as any)?.copyUrl || this.apiUrl}/${id}`;
    return {
      type: this.actionType(STANDARD_ACTION_TYPES.COPY),
      payload: axios.get<any>(requestUrl,{params:{ignoreArr}}),
    };
  };


  createEntity: CrudPostAction<T> = entity => async dispatch => {
    return await dispatch({
      type: this.actionType(STANDARD_ACTION_TYPES.CREATE),
      payload: axios.post((this.apiUrl as any)?.createUrl || this.apiUrl, cleanEntity(entity)),
    });
  };

  updateEntity: CrudPutAction<T> = (entity) => async dispatch => {
    const requestUrl = (this.apiUrl as any)?.editUrl ? `${(this.apiUrl as any).editUrl}/${entity.id}` : `${this.apiUrl}/${entity.id}`;
    return await dispatch({
      type: this.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.put(requestUrl, cleanEntity(entity)),
    })
  };

  updateEntityLevelChange: CrudPutAction<T> = (entity) => async dispatch => {
    const requestUrl = (this.apiUrl as any)?.editUrl ? `${(this.apiUrl as any).editUrl}/${entity.id}` : `${this.apiUrl}/${entity.id}`;
    return await dispatch({
      type: this.actionType(STANDARD_ACTION_TYPES.UPDATE_LEVEL_CHANGE),
      payload: axios.put(requestUrl, cleanEntity(entity)),
    })
  };

  deleteEntity: CrudDeleteAction<T> = (id, remove, deleteEntityParams) => async dispatch => {
    const requestUrl = (this.apiUrl as any)?.deleteUrl ? `${(this.apiUrl as any).deleteUrl + deleteEntityParams}` : `${this.apiUrl}/${id}`;
    return await dispatch({
      type: this.actionType(STANDARD_ACTION_TYPES.DELETE),
      payload: axios.delete(requestUrl, {
        params: { remove, id }
      }),
    });
  };

  reset = (entity?: T) => (dispatch: any) => {
    return dispatch({
      type: this.actionType(STANDARD_ACTION_TYPES.RESET),
      payload: {
        updating: false,
        updateSuccess: false,
        createSuccess: false,
        entity: {
          ...this.defaultItem,
          ...entity
        }
      }
    })
  };
};
