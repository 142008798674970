import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormRadio, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./course.reducer";
import { Post } from "model/video/post.model";
import { Col, Form, message, Row } from 'antd';
import UploadImgComplex from "components/upload-img/upload-img-complex";
import { uploadImage } from "utils/file-utils";
import UploadImgOne from "components/upload-img/upload-img-one";
import { getAllExam } from "../examDecorate/platform.reducer";
import { getAllCertificate } from "../certificate/certificate.reducer";


export interface PostEditProps extends EditStateAndDispatchProps<any>, StateProps, DispatchProps, RouteComponentProps {
}
const PostEdit: FC<PostEditProps> = (props) => {
  const { entity } = props;
  const [contentUrlArray, setContentUrlArray] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [entityData, setEntityData] = useState<any>();
  const [form] = Form.useForm();

  useEffect(() => {
    // 初始化数据
    setEntityData({
      publicityType: 'imageText',
      videoUploadMethod: 'upload'
    })
    if (entity.id) {
      const entityCopy = JSON.parse(JSON.stringify(entity));
      entityCopy.isExamination = entityCopy.isExamination === 0 ? false : true
      // 详细图片
      var picArray = entity.contentUrl && entity.contentUrl.split(',').map((item: any) => {
        return {
          pictureUrl: item,
          id: Math.random()
        }
      })
      setContentUrlArray(picArray);

      // 资料
      var dataArray = entity.information && entity.information.split(',').map((item: any) => {
        return {
          pictureUrl: item,
          id: Math.random()
        }
      })
      setDataArray(dataArray);

      entityCopy.publicityType = entityCopy.voiceUrl ? 'voice' : entityCopy.contentUrl ? 'imageText' : entityCopy.videoUrl ? 'Video' : ''
      setEntityData(entityCopy)
      if (entityCopy.publicityType === 'Video') {
        entityCopy.videoUploadMethod = 'upload'
      }
    }
  }, [entity]);



  const beforeUpdateEntity = (res?: any) => {
    return new Promise<Post>((resolve, reject) => {
      if (res) {
        res.state = res.state ? 1 : 0
        res.isExamination = res.isExamination ? 1 : 0
        res.examPageId = res.examPageId instanceof Array ? res.examPageId.join(',') : res.examPageId

        if (res.publicityType === 'imageText') {
          res.videoUrl = ''
          res.frontCoverUrl = ''
        } else if (res.publicityType === 'voice') {
          res.videoUrl = ''
          res.contentUrl = ''
        } else if (res.publicityType === 'Video') {
          res.voiceUrl = ''
          res.contentUrl = ''
        }
        // 资料
        const p4 = new Promise((resolve, reject) => {
          const picturesData: any = res.information?.concat();
          if (picturesData && picturesData.length > 0) {
            let picturesText: any = [], successList: any = [];
            // 配图已更新或需上传 递归解决上传
            const uploadPictures = () => {
              const newDate = new Date().getTime();
              if (picturesData[successList.length].originFileObj) {
                uploadImage(picturesData[successList.length].originFileObj, 'material/data-' + newDate).then(imageName => {
                  successList.push(1);
                  picturesText.push({ pictureUrl: imageName, type: 'picture' });
                  if (successList.length === picturesData.length) {
                    res.information = picturesText;
                    resolve(true)
                  } else {
                    uploadPictures();
                  }
                }).catch(() => { message.error('图片上传失败'); reject() });
              } else {
                successList.push(1);
                const id = (dataArray.filter((e: any) => e.pictureUrl === picturesData[successList.length - 1].url)[0] as any).id;
                picturesText.push({ pictureUrl: picturesData[successList.length - 1].url, type: 'picture', id });
                if (successList.length === picturesData.length) {
                  res.information = picturesText;
                  resolve(true)
                } else {
                  uploadPictures();
                }
              }
            }; uploadPictures();
          } else {
            res.information = [];
            resolve(true)
          }
        })

        // 课程封面
        const p3 = new Promise((resolve, reject) => {
          const coverData: any = res.frontCoverUrl?.concat();
          if (coverData && coverData.length > 0) {
            let frontCoverUrlData: any = '', successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadCoverUrl = () => {
              const newDate = new Date().getTime();
              if (coverData[successList.length].originFileObj) {
                uploadImage(coverData[successList.length].originFileObj, 'material/img-' + newDate + '.jpg').then((imageName: any) => {
                  successList.push(1);
                  frontCoverUrlData = imageName
                  if (successList.length === coverData.length) {
                    res.frontCoverUrl = frontCoverUrlData;
                    resolve(true)
                  } else {
                    uploadCoverUrl();
                  }
                }).catch(() => { message.error('视频上传失败'); reject() });
              } else {
                res.frontCoverUrl = coverData[0].url;
                resolve(true)
              }
            }; uploadCoverUrl();
          } else {
            // 无封面
            res.frontCoverUrl = '';
            resolve(true)
          }
        })

        // 详细介绍图片
        const p2 = new Promise((resolve, reject) => {
          const picturesData: any = res.contentUrl?.concat();
          if (picturesData && picturesData.length > 0) {
            let picturesText: any = [], successList: any = [];
            // 配图已更新或需上传 递归解决上传
            const uploadPictures = () => {
              const newDate = new Date().getTime();
              if (picturesData[successList.length].originFileObj) {
                uploadImage(picturesData[successList.length].originFileObj, 'material/img-' + newDate).then(imageName => {
                  successList.push(1);
                  picturesText.push({ pictureUrl: imageName, type: 'picture' });
                  if (successList.length === picturesData.length) {
                    res.contentUrl = picturesText;
                    resolve(true)
                  } else {
                    uploadPictures();
                  }
                }).catch(() => { message.error('图片上传失败'); reject() });
              } else {
                successList.push(1);
                const id = (contentUrlArray.filter((e: any) => e.pictureUrl === picturesData[successList.length - 1].url)[0] as any).id;
                picturesText.push({ pictureUrl: picturesData[successList.length - 1].url, type: 'picture', id });
                if (successList.length === picturesData.length) {
                  res.contentUrl = picturesText;
                  resolve(true)
                } else {
                  uploadPictures();
                }
              }
            }; uploadPictures();
          } else {
            res.contentUrl = [];
            resolve(true)
          }
        })





        //视频上传
        const p1 = new Promise((resolve, reject) => {
          const videosData: any = res.videoUrl?.concat();
          if (videosData && videosData.length > 0 && res.publicityType === "Video" && res.videoUploadMethod === 'upload') {
            let videosText: any = [], successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadVideos = () => {
              const newDate = new Date().getTime();
              if (videosData[successList.length].originFileObj) {
                uploadImage(videosData[successList.length].originFileObj, 'material/video-' + newDate + '.mp4').then((imageName: any) => {
                  successList.push(1);
                  videosText.push({
                    mediaUrl: imageName,
                    type: 'video'
                  });
                  if (successList.length === videosData.length) {
                    res.videoUrl = videosText;
                    resolve(true)
                  } else {
                    uploadVideos();
                  }
                }).catch(() => { message.error('视频上传失败,请压缩后再上传'); reject() });
              } else {
                res.videoUrl = videosData[0].url;;
                resolve(true)
              }
            }; uploadVideos();
          } else if (res.publicityType === "Video" && res.videoUrl) {
            // res.videoUrl = res.videoUrl
            resolve(true)
          } else {
            // 无视频
            res.videoUrl = '';
            resolve(true)
          }
        })

        Promise.all([p1, p2, p3, p4])
          .then(result => {
            let data = { ...res }
            // 详细图片
            let contentUrl = data.contentUrl.map((item: any) => {
              return item.pictureUrl || item.url
            })
            data.contentUrl = contentUrl.join(',')
            // 资料
            let dataUrl = data.information.map((item: any) => {
              return item.pictureUrl || item.url
            })
            data.information = dataUrl.join(',')

            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject()
          })
      };
    })
  };

  return (
    <EditPage<Post>
      {...props}
      entity={{
        ...entityData,
      }}
      title={'课程'}
      width={700}
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row justify="start">
        <Col span={11}>
          <ProFormText
            rules={[{ required: true }]}
            name="title"
            label="课程名称"
          />
        </Col>
        <Col span={1} />
        <Col span={12}>
          <ProFormText
            rules={[{ required: true }]}
            name="content"
            label="课程描述"
          />
        </Col>
      </Row>

      <Row>
        <ProFormRadio.Group
          label="内容类型"
          name={'publicityType'}
          width='md'
          rules={[{ required: true }]}
          options={[
            {
              label: '图文',
              value: 'imageText',
            }, {
              label: '视频',
              value: 'Video',
            },
            {
              label: '音频',
              value: 'voice',
            }
          ]}
        />
      </Row>
      <Row>
        <ProFormDependency name={['publicityType']}>
          {({ publicityType }) => {
            return publicityType === 'voice' ?
              <>
                <Col span={24} className="scmb0">
                  <Form.Item name="frontCoverUrl" label="封面" validateTrigger='onBlur' rules={[{ required: publicityType === 'voice' ? true : false }]}>
                    <UploadImgComplex
                      listType="picture-card"
                      extra="最多上传1张,支持扩展名: jpg、jpeg、png"
                      title="上传"
                      maxCount={1}
                    />
                  </Form.Item>
                  <Form.Item name="voiceUrl" label="音频" validateTrigger='onBlur' rules={[{ required: publicityType === 'voice' ? true : false }]} >
                    <UploadImgOne
                      listType="text"
                      Immediate={true}
                      upType='any'
                      title="上传"
                    />
                  </Form.Item>
                </Col>
              </>
              : publicityType === 'Video' ? <>
                <Row style={{ width: '100%' }}>
                  <Col span={24} className="scmb0">
                    <Form.Item name="frontCoverUrl" label="封面" rules={[{ required: publicityType === 'Video' ? true : false }]} validateTrigger='onBlur'>
                      <UploadImgComplex
                        listType="picture-card"
                        extra="最多上传1张,支持扩展名: jpg、jpeg、png"
                        title="上传"
                        maxCount={1}
                      />
                    </Form.Item>

                  </Col>
                  <ProFormRadio.Group
                    label="视频上传方式"
                    name={'videoUploadMethod'}
                    width='md'
                    rules={[{ required: publicityType === 'Video' ? true : false }]}
                    options={[
                      {
                        label: '上传',
                        value: 'upload',
                      }, {
                        label: '封面视频链接',
                        value: 'videolink',
                      }
                    ]}
                  />
                </Row>
                <Row>
                  <Col span={24} className="scmb0">
                    <ProFormDependency name={['videoUploadMethod']}>
                      {({ videoUploadMethod }) => {
                        return videoUploadMethod === 'upload' ?
                          <Row>
                            <Col span={24} className="scmb0">
                              <Form.Item name="videoUrl" label="封面视频" validateTrigger='onBlur' rules={[{ required: publicityType === 'Video' ? true : false }]} >
                                <UploadImgComplex
                                  extra="最多上传1个视频，视频限制最大30MB"
                                  listType="text"
                                  title="上传"
                                  fileType='video'
                                  maxCount={1}
                                />
                              </Form.Item>
                            </Col> </Row> : <ProFormText
                            width={'lg'}
                            label="封面视频链接"
                            name="videoUrl"
                            placeholder="视频链接"
                          />
                      }}</ProFormDependency>
                  </Col>
                </Row>
              </> : publicityType === 'imageText' ? <Row>
                <Col span={24}>
                  <Form.Item name="voiceUrl" label="音频" validateTrigger='onBlur' rules={[{ required: publicityType === 'voice' ? true : false }]} >
                    <UploadImgOne
                      listType="text"
                      Immediate={true}
                      upType='any'
                      title="上传"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="contentUrl" label="图文" rules={[{ required: publicityType === 'imageText' ? true : false }]} validateTrigger='onBlur'>
                    <UploadImgComplex
                      listType="picture-card"
                      extra="最多上传9张,支持扩展名: jpg、jpeg、png"
                      title="上传"
                      maxCount={9}
                    />
                  </Form.Item>
                </Col>
              </Row> : ''
          }}
        </ProFormDependency>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="information" label="资料" validateTrigger='onBlur'>
            <UploadImgComplex
              listType="text"
              extra="最多上传9个"
              title="上传"
              fileType='any'
              maxCount={9}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mb20" style={{ marginBottom: '10px' }}>
        <Col span={12}>
          <ProFormText
            rules={[{ required: true }]}
            name={'passScore'}
            label="及格分数"
          />
        </Col>
        <Col span={1} />
        <Col span={11}>
          <ProFormSwitch
            name={'isExamination'}
            label="是否需要考试"
            initialValue={true}
            fieldProps={{
              checkedChildren: '需要',
              unCheckedChildren: '不需要'
            }}
          />
        </Col>
      </Row>

      <Row className="mb20" style={{ marginBottom: '10px' }}>
        <Col span={24}>
          <ProFormSelect
            style={{ 'width': '100%' }}
            allowClear={true}
            width={'lg'}
            request={async () => {
              const list: any = await getAllExam();
              return list.map((item: any) => ({
                value: item.id,
                label: item.title
              }))
            }}
            name="examPageId"
            label="关联考试"
            placeholder="选择考试内容"
          />
        </Col>
      </Row>

      <Row className="mb20" style={{ marginBottom: '10px' }}>
        <Col span={24}>
          <ProFormSelect
            style={{ 'width': '100%' }}
            allowClear={true}
            width={'lg'}
            request={async () => {
              const list: any = await getAllCertificate();
              return list.map((item: any) => ({
                value: item.id,
                label: item.title
              }))
            }}
            name="certificateId"
            label="关联证书"
            placeholder="选择证书"
          />
        </Col>
      </Row>

    </EditPage >);
};

const mapStateToProps = ({ course }: RootState) => ({
  entity: course.entity,
  updateSuccess: course.updateSuccess,
  updating: course.updating,
  createSuccess: course.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostEdit);
