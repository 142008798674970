import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./certificate.reducer";
import { Post } from "model/video/post.model";
import { Col, Form, Row, Image, Mentions, Modal } from 'antd';
import Button from 'antd/es/button';

export interface TopicEditProps extends EditStateAndDispatchProps<any>, StateProps, DispatchProps, RouteComponentProps {
}
const PostEdit: FC<TopicEditProps> = (props) => {
  const { entity } = props;
  const [entityData, setEntityData] = useState<any>();
  const [certificateVisible, setCertificateVisible] = useState<any>(false);
  const [certificatePictureUrl, setCertificatePictureUrl] = useState<any>('https://yumei-platform.oss-cn-hangzhou.aliyuncs.com/public/common/certificate1.png');
  const [variable] = useState<any>({
    name: '${name}',
    time: '${time}'
  });
  const [form] = Form.useForm();

  useEffect(() => {
    // 初始化数据
    setEntityData({
      publicityType: 'coverImg',
      videoUploadMethod: 'upload',
      text: 'https://yumei-platform.oss-cn-hangzhou.aliyuncs.com/public/common/certificate1.png'

    })
    if (entity.id) {
      const entityCopy = JSON.parse(JSON.stringify(entity));
      setEntityData(entityCopy);
    }
  }, [entity]);



  const beforeUpdateEntity = (res?: any) => {
    return new Promise<Post>((resolve, reject) => {
      if (res) {
        res.text = certificatePictureUrl || ''
        resolve(res)
      }
    })
  };

  return (
    <EditPage<Post>
      {...props}
      entity={{
        ...entityData,
      }}
      beforeUpdateEntity={beforeUpdateEntity}
      title={'证书'}
      width={700}
      form={form}
    >

      <Row>
        <Col span={24}>
          <ProFormText
            rules={[{ required: true }]}
            name={'title'}
            label="表彰名称"
            fieldProps={{
              onChange:(e:any)=> {
                  console.log(e.target.value)
                  setEntityData({
                    ...entityData,
                    title: e.target.value
                  })
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ProFormText
            hidden
            name={'issueText'}
          />
          <ProFormText
            hidden
            name={'variable'}
          />
          <Form.Item label="证书文案" rules={[{ required: true }]}>
            <Mentions
              value={entityData?.issueText || ''}
              prefix={'$'}
              style={{ width: '100%' }}
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder="兹证明${variable.name}(女士/先生), 于${variable.time}参加美人计集团商学院皮肤管理师培训，通过考核，成绩合格。授予"
              onChange={(e) => {
                console.log(e)
                form.setFieldsValue({
                  'issueText': e
                })
                setEntityData({
                  ...entityData,
                  issueText: e
                })
                let name = e.trim().indexOf('{name}') > 0 ? 'name' : ''
                let time = e.trim().indexOf('{time}') > 0 ? 'time' : ''
                let list = [name, time].filter(item => item.trim())
                form.setFieldsValue({
                  'variable': list.join(',')
                })
               
              }}
              options={[
                {
                  value: '{name}',
                  label: '${name}',
                },
                {
                  value: '{time}',
                  label: '${time}',
                }
              ]}
            />
            <div style={{ marginTop: '20px' }}>
              <Col span={24}>变量: {variable.name}: 代表姓名, {variable.time}: 代表年月(xx年xx月) </Col>
              <Col span={24}>变量格式: 兹证明{variable.name}(女士/先生), 于{variable.time}参加美人计集团商学院皮肤管理师培训，通过考核，成绩合格。授予 </Col>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row className="certificate-row" key={certificatePictureUrl}>
        <Col span={24}>
          <ProFormSelect
            style={{ 'width': '100%' }}
            rules={[{ required: true }]}
            options={[{
              label: '背景一',
              value: 'https://yumei-platform.oss-cn-hangzhou.aliyuncs.com/public/common/certificate1.png',
            }, {
              label: '背景二',
              value: 'https://yumei-platform.oss-cn-hangzhou.aliyuncs.com/public/common/certificate2.png',
            }]}
            fieldProps={{
              onChange: (e) => {
                setCertificatePictureUrl(e)
              }
            }}
            name={'text'}
            label="证书背景图"
            placeholder="选择证书背景图"
          />
        </Col>
      </Row>
      <Row className="certificatePictureUrl">
        <Image src={certificatePictureUrl} style={{ width: '150px' }}></Image>
      </Row>
      <Row>
        <Button onClick={()=>setCertificateVisible(true)}>查看证书示例</Button>
      </Row>

     
      <Modal
        title="证书示例"
        wrapClassName='certificate-pop'
        visible={certificateVisible}
        onCancel={() => {
          setCertificateVisible(false)
      }}
        width={430}
      >
        {/* 证书合成图片 */}
        <div className={`htmltoImage-wrapper ${certificatePictureUrl==='https://yumei-platform.oss-cn-hangzhou.aliyuncs.com/public/common/certificate2.png'? 'cae2':''}`}>
          <Image src={certificatePictureUrl} style={{ width: '400px' }} className="certificate-img"></Image>
          <div className="certificate-text">{entityData?.issueText}</div>
          <div className="certicate-name">{entityData?.title}</div>
        </div>

      </Modal>

    </EditPage >);
};

const mapStateToProps = ({ examtopic }: RootState) => ({
  entity: examtopic.entity,
  updateSuccess: examtopic.updateSuccess,
  updating: examtopic.updating,
  createSuccess: examtopic.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostEdit);
