import { FC, useCallback, useState, memo, useEffect, createContext } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { MenuOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { updatePageConfig, updateInsertPositionId, updateMoreState, updateModuleData, deleteModule, changePlatform } from '../examDecorate/examplatform-edit.reducer'
import { modulesList } from 'model/decorate/examdecorate.model'
import { Tabs } from 'antd';

import { TitleTextSet } from './modules/title_text';
import { SingleChoiceQuestion } from './modules/single_choice_question';
import { MultipleChoiceQuestions } from './modules/multiple_choice_questions';
import { ConfigSet } from './modules/config';
const { TabPane } = Tabs;
interface ModulesSettingProps extends StateProps, DispatchProps { }

interface ModuleItemProps {
  onDrop: (item: any) => void
  id: string
  module?: any
  insertPositionId: string
  updatePageConfigPrivate: () => void
  findModulePrivate: (id: string) => { module: any, index: number }
  moveModulePrivate: (dragId: string, index: number) => void
  moveAtIndex?: number
  updateInsertPositionId: (id: string) => void
  updateMoreState: (props: { [key: string]: any }) => void
  settingModuleId?: string
  moduleData: any
  deleteModule?: any
  language: string
  platform: string
}
interface DropResult {
  name: string
}

const onDrop = (moduleData: any, dragInfo: any) => {
}
const getModuleName = (type: string) => {
  const moduleData = modulesList.filter((v: any) => `${v.type}` === type)[0]
  if (moduleData) {
    return moduleData.title
  }
}
const ModuleItem: FC<ModuleItemProps> = (({ moduleData, onDrop, id, updatePageConfigPrivate, findModulePrivate, moveModulePrivate, moveAtIndex, updateMoreState, settingModuleId, deleteModule, language, platform }) => {
  const expand = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['all'],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => false,
    hover(props) {
      //组件位置调整
      if (props.id && props.id !== id) {
        const { index } = findModulePrivate(id);
        moveModulePrivate(props.id, index)
      }

    },
  }, [onDrop, id, findModulePrivate, moveModulePrivate])

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'all',
    item: { id },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      if (item && dropResult && moveAtIndex !== -1) {
        updatePageConfigPrivate()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }), [id, moveAtIndex, moveModulePrivate])
  return <div className={`module-item ${settingModuleId === id && 'selected'} ${isDragging && 'dragging'} ${expand && 'expand'}`} >
    <div className={`module-header  `} ref={(node) => drag(drop(node))}>
      <MenuOutlined className="icon" />
      <p className="title text-1">{getModuleName(moduleData.type)}</p>
      <div className="arrow-con">
        <SettingOutlined className="arrow" onClick={
          () => {
            updateMoreState({
              settingModuleId: id
            })
          }
        } />
        <DeleteOutlined className="delete" onClick={
          () => {
            updateMoreState({
              settingModuleId: id + 'delete'
            });
            deleteModule(id)
          }
        } />
      </div>

    </div>
    {/* <div className="module-content">
            {module}
        </div> */}
  </div>
})

/**获取模块设置模块
 * @param module 模块信息
 * @param methods 操作方法
 * @param selectedInfo 选中的链接/商品信息
 * 
 * 
*/
const getModuleSetModule = (module: { type: string, id: string, dataField: any }, methods: any, selectedInfo: any, otherInfo: any) => {
  switch (module.type) {
    case 'title_text':
      return <TitleTextSet otherInfo={otherInfo} dataField={module.dataField} id={module.id} key={module.id} methods={methods} selectedInfo={selectedInfo} />
    case 'single_choice_question':
      return <SingleChoiceQuestion otherInfo={otherInfo} dataField={module.dataField} id={module.id} key={module.id} methods={methods} selectedInfo={selectedInfo} />
      case 'multiple_choice_questions':
      return <MultipleChoiceQuestions otherInfo={otherInfo} dataField={module.dataField} id={module.id} key={module.id} methods={methods} selectedInfo={selectedInfo} />
    default:
      return <div className="none">请选择模块</div>
  }
}

export const SkytoneCardContext = createContext<{ SkytoneCard?: boolean }>({ SkytoneCard: false });
const ModulesSetting: FC<ModulesSettingProps> = memo(({ pageData, pageConfig, insertPositionId, updatePageConfig, updateInsertPositionId, updateMoreState, settingModuleId, updateModuleData, deleteModule, language, platform }) => {
  const [pageConfigPrivate, setPageConfigPrivate] = useState(pageConfig);
  const [moveAtIndex, setMoveMoveAtIndex] = useState(-1)
  const [tabActive, setTabActive] = useState('3')
  const [settingModule, setSettingModule] = useState(<div></div>);
  const [selectedInfo, setSelectedInfo] = useState({})//选中的信息详情

  const findModulePrivate = useCallback(
    (id: string) => {
      const module = pageConfigPrivate.filter((c: any) => `${c.id}` === id)[0]
      return {
        module,
        index: pageConfigPrivate.indexOf(module)
      }
    },
    [pageConfigPrivate],
  )
  const moveModulePrivate = useCallback(
    (dropId: string, index: number) => {
      const { module, index: originIndex } = findModulePrivate(dropId)
      setMoveMoveAtIndex(index)
      // console.log('index',index,'originIndex',originIndex)
      setPageConfigPrivate(
        update(pageConfigPrivate, {
          $splice: [
            [originIndex, 1,],
            [index, 0, module]
          ]
        })
      )
    },
    [pageConfigPrivate, findModulePrivate, setMoveMoveAtIndex, setPageConfigPrivate]
  )
  const updatePageConfigPrivate = useCallback(() => {
    updatePageConfig(pageConfigPrivate)
  }, [pageConfigPrivate, updatePageConfig])

  const setShowLinkSelectionPrivate = useCallback(
    (show: boolean, returnData: any) => {
      setSelectedInfo(returnData);
      setTimeout(() => {
        setSelectedInfo({});
      }, 1000)
    }
    , [])
  const setShowProductSelectionPrivate = useCallback(
    (show: boolean, returnData: any) => {
      setSelectedInfo(returnData);
      setTimeout(() => {
        setSelectedInfo({});
      }, 1000)

    }
    , [])

  useEffect(() => {
    setPageConfigPrivate(pageConfig);
  }, [pageConfig])

  useEffect(() => {
    const { module } = findModulePrivate(settingModuleId), methods = {
      updateModuleData,
      setShowLinkSelectionPrivate,
      setShowProductSelectionPrivate,
      updateMoreState
    };
    let otherInfo = {
      settingModuleId,
      language
    }
    if (module) {
      const SkytoneCard = pageConfig.concat().filter((e: any) => e.type === 'config')[0]?.dataField?.SkytoneCard;
      setSettingModule(getModuleSetModule(
        {
          ...module,
          dataField: {
            ...module.dataField,
            SkytoneCard,
          }
        },
        methods, selectedInfo, otherInfo)
      )
    } else {
      const configModule = pageConfigPrivate?.filter((v: any) => {
        return `${v.type}` === 'config'
      })[0];
      configModule && setSettingModule(
        <ConfigSet language={language} platform={platform} otherInfo={otherInfo} dataField={configModule.dataField} pageData={pageData} pageConfig={pageConfigPrivate} methods={methods} selectedInfo={selectedInfo} id={configModule.id} />
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSettingModule, settingModuleId, pageConfigPrivate, findModulePrivate, updateModuleData, setShowLinkSelectionPrivate, selectedInfo])
  useEffect(() => {
    const moduleIdArr = settingModuleId ? settingModuleId.split('delete') : [];
    moduleIdArr.length > 1 ? setTabActive('1') : (moduleIdArr[0] === 'none' ? setTabActive('1') : setTabActive('2'));
  }, [settingModuleId])
  const [, drop] = useDrop(() => ({ accept: 'all' }))
  return <div className="modules-setting" ref={drop}>
    <Tabs activeKey={tabActive} className="custom-tabs" onChange={(e) => {
      setTabActive(e);
      const { module } = findModulePrivate(settingModuleId);
      const methods = {
        updateModuleData,
        setShowLinkSelectionPrivate,
        setShowProductSelectionPrivate,
        updateMoreState
      };
      let otherInfo = {
        settingModuleId,
        language
      };
      if (e === '3') {
        const configModule = pageConfigPrivate?.filter((v: any) => {
          return `${v.type}` === 'config'
        })[0]
        console.log(configModule)
       console.log('+++++++++++++++++')

        configModule && setSettingModule(
          <ConfigSet language={language} platform={platform} otherInfo={otherInfo} dataField={configModule.dataField} pageData={pageData} pageConfig={pageConfigPrivate} methods={methods} selectedInfo={selectedInfo} id={configModule.id} />
        )
      } else if (e === '2') {
        const SkytoneCard = pageConfig.concat().filter((e: any) => e.type === 'config')[0]?.dataField?.SkytoneCard;
        setSettingModule(getModuleSetModule(
          {
            ...module,
            dataField: {
              ...module?.dataField,
              SkytoneCard,
            }
          },
          methods, selectedInfo, otherInfo)
        );
      }
    }}>
      <TabPane tab="模块列表" key="1">
        {pageConfigPrivate?.map((v: any, i: number) => {
          return v.type !== 'config' && <ModuleItem
            moveAtIndex={moveAtIndex}
            updateInsertPositionId={updateInsertPositionId}
            findModulePrivate={findModulePrivate}
            moveModulePrivate={moveModulePrivate}
            insertPositionId={insertPositionId}
            key={v.id}
            updatePageConfigPrivate={updatePageConfigPrivate}
            updateMoreState={updateMoreState}
            id={v.id}
            moduleData={v}
            deleteModule={deleteModule}
            onDrop={(item) => onDrop(v, item)}
            settingModuleId={settingModuleId}
            language={language}
            platform={platform}
          />
        })}
      </TabPane>
      <TabPane tab="模块设置" key="2">
        {tabActive === '2' && settingModule}
      </TabPane>
      <TabPane tab="页面设置" key="3">
        {tabActive === '3' && settingModule}
      </TabPane>

    </Tabs>
  </div>
})
const mapStateToProps = ({ examDecorateEdit }: RootState) => ({
  pageConfig: examDecorateEdit.pageConfig,
  pageData: examDecorateEdit.pageData,
  language: examDecorateEdit.language,
  platform: examDecorateEdit.platform,
  insertPositionId: examDecorateEdit.insertPositionId,
  settingModuleId: examDecorateEdit.settingModuleId,
});
const mapDispatchToProps = {
  updatePageConfig,
  updateMoreState,
  updateInsertPositionId,
  updateModuleData,
  deleteModule,
  changePlatform
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ModulesSetting);
