import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormRadio, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./material.reducer";
import { Col, Form, message, Row } from 'antd';
import { uploadImage } from "utils/file-utils";
import UploadImgOne from "components/upload-img/upload-img-one";
import UploadImgComplex from "components/upload-img/upload-img-complex";


export interface PostEditProps extends EditStateAndDispatchProps<any>, StateProps, DispatchProps, RouteComponentProps, StateProps, DispatchProps {
  entityData?: any
}
const PostEdit: FC<PostEditProps> = (props) => {
  const { entityData, id } = props;
  console.log(props,'props===========');
  const [neweNtity, setEntityData] = useState<any>();
  const [form] = Form.useForm();

  useEffect(() => {
    console.log(entityData,'-======')
    setEntityData({
      type: 1,
      videoUploadMethod: 'upload'
    })
    if (entityData) {
      let data = entityData.concat().filter((e: any) => e.id === id)?.[0]
      if (data) {
        setEntityData(data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityData]);



  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        const p1 = new Promise((resolve, reject) => {
          const text: any = res && res.text?.concat();
          if (res.text && text.length > 0) {
            if (text[0].originFileObj) {
              //logo需上传
              const newDate = new Date().getTime();
              uploadImage(text[0].originFileObj, 'product/img-' + newDate).then(async imageName => {
                res.text = imageName;
                resolve(res);
              }).catch(() => { message.error('图片上传失败'); reject() });
            } else {
              // logo已上传且未修改
              res.text = text[0].url;
              resolve(res)
            }
          } else {
            //未上传logo
            res.text = '';
            resolve(res)
          }
        })

        //视频上传
        const p2 = new Promise((resolve, reject) => {
          const videosData: any = res.videoUrl?.concat();
          if (videosData && videosData.length > 0 && res.type === 2 && res.videoUploadMethod === 'upload') {
            let videosText: any = [], successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadVideos = () => {
              const newDate = new Date().getTime();
              if (videosData[successList.length].originFileObj) {
                uploadImage(videosData[successList.length].originFileObj, 'material/video-' + newDate + '.mp4').then((imageName: any) => {
                  successList.push(1);
                  videosText.push({
                    mediaUrl: imageName,
                    type: 'video'
                  });
                  if (successList.length === videosData.length) {
                    res.text = videosText;
                    resolve(res)
                  } else {
                    uploadVideos();
                  }
                }).catch(() => { message.error('视频上传失败,请压缩后再上传'); reject() });
              } else {
                res.text = videosData[0].url;;
                resolve(res)
              }
            }; uploadVideos();
          } else if (res.type === 2) {
            res.text = res.videoUrl
            resolve(res)
          } else {
            // 无视频
            res.text = '';
            resolve(res)
          }
        })


        Promise.all([res.type === 1 ? p1 : p2])
        .then(result => {
          resolve(res)
        })
        .catch(e => {
          console.log(e)
          reject()
        })


      }
    })
  }

  return (
    <EditPage<any>
      {...props}
      entity={{
        ...neweNtity
      }}
      title={'素材'}
      width={500}
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row justify="start">
        <Col span={24}>
          <ProFormText
            rules={[{ required: true }]}
            name="name"
            label="名称"
          />
        </Col>
      </Row>

      <Row>
        <ProFormRadio.Group
          label="封面类型"
          name='type'
          width='md'
          rules={[{ required: true }]}
          options={[
            {
              label: '图片',
              value: 1
            }, {
              label: '视频',
              value: 2
            },
          ]}
        />
      </Row>

      <Row>
        <ProFormDependency name={['type']}>
          {({ type }) => {
            return type === 1 ?
              <>
                <Col span={24} className="scmb0">
                  <Form.Item name="text" label="图片" validateTrigger='onBlur'>
                    <UploadImgOne
                      listType="picture-card"
                      extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
                      title="上传"
                    />
                  </Form.Item>
                </Col>
              </>
              : <>
                <Row>

                  <ProFormRadio.Group
                    label="视频上传方式"
                    name={'videoUploadMethod'}
                    width='md'
                    rules={[{ required: type === 2 ? true : false }]}
                    options={[
                      {
                        label: '上传',
                        value: 'upload',
                      }, {
                        label: '封面视频链接',
                        value: 'videolink',
                      }
                    ]}
                  />
                  <Col span={24} className="scmb0">
                    <ProFormDependency name={['videoUploadMethod']}>
                      {({ videoUploadMethod }) => {
                        return videoUploadMethod === 'upload' ?
                          <Row>
                            <Col span={24} className="scmb0">
                              <Form.Item name="videoUrl" label="视频" validateTrigger='onBlur' rules={[{ required: type === 2 ? true : false }]} >
                                <UploadImgComplex
                                  extra="最多上传1个视频，视频限制最大30MB"
                                  listType="text"
                                  title="上传"
                                  fileType='video'
                                  maxCount={1}
                                />
                              </Form.Item>
                            </Col> </Row> : <ProFormText
                            width={'lg'}
                            label="封面视频链接"
                            name="videoUrl"
                            placeholder="视频链接"
                          />
                      }}</ProFormDependency>
                  </Col>
                </Row>
              </>

          }}
        </ProFormDependency>
      </Row>
    </EditPage >);
};

const mapStateToProps = ({ material }: RootState) => ({
  entity: material.entity,
  updateSuccess: material.updateSuccess,
  updating: material.updating,
  createSuccess: material.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostEdit);
