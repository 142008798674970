import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, message, Space, Tooltip, Upload, Row, Col, Switch } from 'antd';
import { useEffect, useState } from "react";

export interface SpecEditListProps {
  onChange?: (value: any[]) => void;
  value?: any[];
  specListDataChange: (res: any) => void;
}
const SpecEditList = (props: SpecEditListProps) => {
  const { value, onChange } = props;

  const [specListData, setSpecListData] = useState<any[]>([]);//specList 总数据
  const [inputVisible, setInputVisible] = useState<number | string>('');//tag 标签展示 ｜ 输入框展示
  const [tagValChange, setTagValChange] = useState('');//tag值

  const specData = {
    title: '',
    id: '',
    specValueList: []
  };
  useEffect(() => {
    if (value) {
      setSpecListData(value);
      props.specListDataChange(value);
    } else {
      setSpecListData([specData]);
      onChange?.([specData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  // const tagReduce = (res: any, indexId: number) => {
  //   const data: any = specListData;
  //   data[indexId].specValueList.forEach((element: any, index: number) => {
  //     if (element === res) {
  //       const specValueListData = data[indexId].specValueList;
  //       specValueListData.splice(index, 1);
  //       specListDataChange(indexId, '', '', specValueListData, false);
  //       return;
  //     }
  //   });
  // };

  const specListDataChange = (specIndex?: Number | string, title?: string, id?: string | number, tagsData?: any, addSpec?: boolean, deleteSpec?: Boolean) => {
    //更新总数据
    // specIndex:下标   title:title    id:id   tagsData:tag数组  addSpec:是否新增商品规格列表  deleteSpec:是否执行删除 
    const data: any = specListData.map((res: any, index: number) => {
      if (index === specIndex) {
        if (title !== '') {
          res.title = title
          res.id = id;
          if (tagsData.length > 0) {
            res.specValueList = tagsData;
            return res;
          } else {
            return res;
          }
        } else {
          if (tagsData.length > 0) {
            res.specValueList = tagsData;
            return res;
          } else {
            return res;
          }
        }
      } else {
        return res;
      }
    });
    if (addSpec) {
      const length = data.length - 1;
      if (data.length > 0) {
        if (data[length].title === '' || data[length].specValueList.length === 0) {
          message.info('请填完整最后一行规格！');
        } else {
          data.push(specData);
        }

      } else {
        data.push(specData);
      }
    };
    deleteSpec && data.splice(specIndex, 1);
    setSpecListData(data);
    onChange?.(data);
    props.specListDataChange && props.specListDataChange(data);
  }
  const tagDataAdd = (index: number) => {
    if (tagValChange) {
      const val = {
        specValue: tagValChange,
        specId: value && value[index].id,
        imgUrl: '',
        // sort: specListData[index].specValueList.length
        sort: 0,
      };
      const data: any = specListData[index].specValueList;
      data.push(val);
      setInputVisible('');
      specListDataChange(index, '', '', data, false);
    }
  }

  const handleMainPicture = (file: any, res: any, indexId: number) => {
    const data: any = specListData;
    data[indexId].specValueList.forEach((element: any, index: number) => {
      if (element === res) {
        const specValueListData = data[indexId].specValueList;
        specValueListData[index].imgUrl = file.fileList;
        specListDataChange(indexId, '', '', specValueListData, false);
        return;
      }
    });
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return <>
    {
      value && value.length > 0 &&
      (<div className="spec-box">
        {/* <div className="ant-pro-form-group-title">商品规格列表</div> */}
        <Row>
          {value.map((item: any, index: number) => {
            return <><Col span={24}>
              <Space style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: 600 }}> 规格名：</span>
                <Input
                  width="xs"
                  defaultValue={item.title}
                  key={item.id}
                  onBlur={async (res: any) => {
                    // const data = String(new Date().getTime());
                    specListDataChange(index, res.target.value, '', [], false);
                  }}
                />
                <Tooltip title={'删除当前行数据'} key="AuditReject">
                  <Button type="link" size="small" danger
                    onClick={() => { specListDataChange(index, '', '', [], false, true) }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Space>
            </Col>
              <Col span={24} style={{ marginBottom: '30px' }}>
                <Space className="spec-val-box" style={{ alignItems: 'baseline' }}>
                  <div className="spece-left">规格值：</div>
                  <div className="spece-right">
                    {item.specValueList.map((res: any, specValueListIndex: number) =>
                      <Space size={1} key={res.specValue} wrap style={{ marginBottom: '4px', marginRight: '30px', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Tooltip title={res.specValue} key={res.specValue}>
                          {/* //删除当前规格 */}
                          {/* onClick={() => tagReduce(res, index)} */}
                          <Button className="spec-val-btn" size='small' >{res.specValue}</Button>
                        </Tooltip>
                        <div style={{ margin: '5px 0 0' }}>
                          <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={res.status === 1 ? true : false} onChange={(v) => {
                            const arr = item.specValueList.concat().map((e: any, s: number) => {
                              return {
                                ...e,
                                status: s === specValueListIndex ? (v ? 1 : 0) : e.status,
                              }
                            });
                            specListDataChange(index, '', '', arr, false, false);
                          }} />
                        </div>
                        <Input
                          width={100}
                          defaultValue={res.sort}
                          key={res.specValue}
                          title='排序值'
                          style={{ margin: '6px 0px 20px' }}
                          onBlur={async (value: any) => {
                            // const data = String(new Date().getTime());
                            const arr = item.specValueList.concat().map((e: any, s: number) => {
                              return {
                                ...e,
                                sort: s === specValueListIndex ? Number(value.target.value || 0) : Number(e.sort || 0),
                              }
                            }).sort(function (a: any, b: any) {
                              return a.sort - b.sort; // 升序排序  
                            });

                            specListDataChange(index, '', '', arr, false, false);
                          }}
                        />
                        {index === 0 && <Upload
                          listType="picture-card"
                          fileList={(res.imgUrl && res.imgUrl !== '') ? (typeof (res.imgUrl) === 'string' ? [{
                            name: 'image.png',
                            status: 'done',
                            uid: res.imgUrl,
                            url: res.imgUrl,
                          }] : res.imgUrl) : []}
                          onChange={(file) => handleMainPicture(file, res, index)}
                          maxCount={1}
                        >{(res.imgUrl && res.imgUrl.length > 0) ? null : uploadButton}</Upload>}
                      </Space>
                    )}
                    {inputVisible === index && (
                      <Input
                        type="text"
                        size="small"
                        onChange={(res) => setTagValChange(res.target.value)}
                        onBlur={async () => {
                          tagDataAdd(index)
                        }}
                      />
                    )}
                    <Button className="site-tag-plus" onClick={() => setInputVisible(index)}>
                      <PlusOutlined /> 规格值
                    </Button>

                    {/* <ModalForm
                    title="规格排序"
                    preserve={false}
                    trigger={<Tag color="#1890ff" className="site-tag-plus" onClick={() => setInputVisible(index)}>
                      <EditOutlined /> 规格排序
                    </Tag>}
                    onFinish={async (data: any) => {
                      console.log(data);
                      return true;
                    }}
                  >

                  </ModalForm> */}
                  </div>
                </Space>
              </Col></>
          })}
        </Row>
      </div>)
    }
    <Button style={{ marginBottom: '-20px' }} type="dashed" icon={<PlusOutlined />} onClick={() => { specListDataChange('', '', '', [], true) }} >商品规格列表</Button>
  </>
}

export default SpecEditList;