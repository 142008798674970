/* eslint-disable react-hooks/exhaustive-deps */
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers/index';
import {getSession, getQueryParamGeneral, MenusItem} from './authentication';
import { connect } from 'react-redux';
import React, { createContext, useEffect } from 'react';
import { Storage } from 'utils/storage-util';
export interface AuthorizedRouteProps extends StateProps, DispatchProps, RouteComponentProps {
  children: React.ReactNode;
  authority?: string[] | string;
  branchForbidden?: boolean;
}
export interface AuthContextProps {
  id?: string,
  roleName?: string,
  roleId?: string[],
  role?: {
    groups?: string[];
    id?: string;
    name?: string;
  },
  isTenantRole?: boolean,
  menus?: MenusItem[];
}

export const AuthContext = createContext<AuthContextProps | null>(null);

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = (props) => {

  const {
    sessionHasBeenFetched,
    isAuthenticated,
    navigate,
    getSession,
    getQueryParamGeneral,
    account,
    location,
  } = props;
  const { children } = props;

   // 获取地址栏参数
  //  const getParam = (name: any) => {
  //   var url_string = window.location.href; // window.location.href
  //   var params = url_string.split('?')[1]; // 获取?号后面的参数 name=mick&age=20
  //   if (!params) return null; // 如果无参，返回null
  //   var vars = params.split("&"); // 将参数分割成数组 ['name=mick', 'age=20']
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("="); // 将参数的key和值进行分割成 ['name', 'mick']
  //     var key = decodeURIComponent(pair[0]); // 参数key
  //     var value: any = decodeURIComponent(pair[1]); // 参数值
  //     if (name === key) { // 如果匹配到对应key返回
  //       return value;
  //     }
  //   }
  //   return null;
  // }

  
  //session
  useEffect(() => {
    const isSession = Storage.session.get('loginData');
    if(!isAuthenticated){
      if(isSession){
        !!isSession.access_token && getSession(isSession.access_token);
        getQueryParamGeneral();
      }else{
        navigate && navigate('/login');
      }
    }
    // 暂存未登录时访问路径
    if(!isSession && location?.pathname){
      Storage.session.set('beforeLoginPathname',location?.pathname)
    }
  }, [isAuthenticated, getSession, navigate]);

  //菜单权限
  useEffect(() => {
    if(isAuthenticated){
      //登陆成功
    }
  }, [account, navigate, location?.pathname,isAuthenticated])
  //登录后调整
  useEffect(() => {
    if(isAuthenticated){
      let beforePath:string = Storage.session.get('beforeLoginPathname')
      if(beforePath){
        navigate && navigate(beforePath)
        Storage.session.remove('beforeLoginPathname')
      }
    }
    
  },[isAuthenticated])
  return (
    <>
      {!sessionHasBeenFetched && <></>}
      {/* {!isAuthenticated &&
      <Redirect
        noThrow
        to="/login"
        state={{
          from: (location && (location.pathname)) || '/'
        }}
      />} */}
      {isAuthenticated &&
        <AuthContext.Provider
          value={{
            id: account.userId,
            role: account.role,
            roleName: account.roleName,
            roleId: account.roleId,
            isTenantRole: account.isTenantRole,
            menus:account.menus || [],
          }}
        >
          {children}
        </AuthContext.Provider>
      }
    </>
  );
};

const mapStateToProps = ({ authentication }: RootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account,
  sessionHasBeenFetched: authentication.sessionHasBeenFetched,
  sessionIsFetching: authentication.sessionIsFetching,
  showModalLogin: authentication.showModalLogin,
  // isAdmin: hasAnyAuthority(authentication.account.roles, [AUTHORITIES.ADMIN]),
});
const mapDispatchToProps = { getSession, getQueryParamGeneral };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
// console.log('AuthorizedRoute',AuthorizedRoute)
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedRoute);
